var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"font-size-14 text--disabled margin-top-2"},[_vm._v(" "+_vm._s(_vm.$t('nodes.dna.status'))+" ")]),_c('span',{staticClass:"font-size-16 ml-2",class:[
      { 'status-color-purple': _vm.status === _setup.DNA_STATUSES.initial },
      { 'status-color-green': _vm.status === _setup.DNA_STATUSES.applied },
      { 'status-color-blue fading': _vm.status === _setup.DNA_STATUSES.canceling },
      { 'status-color-orange': _vm.status === _setup.DNA_STATUSES.modified },
      { 'status-color-blue fading': _vm.status === _setup.DNA_STATUSES.reconfiguring },
      { 'status-color-red': _vm.status === _setup.DNA_STATUSES.undefined },
    ],attrs:{"id":"iiotStatusColor"}},[_vm._v(" "+_vm._s(_vm.$t(`nodes.dna.${_vm.status}`))+" ")]),_c('span',[(_vm.status === _setup.DNA_STATUSES.undefined && _vm.message)?_c('div',{staticClass:"tooltip",on:{"mouseleave":function($event){_setup.openDialog = false}}},[_c('v-img',{staticClass:"ml-2 mt-1 pointer",attrs:{"id":`iiotDNAUndefinedStateIcon`,"src":`/img/warning.svg`,"max-height":"15px","max-width":"15px"},on:{"mouseover":function($event){_setup.openDialog = true}}}),(_setup.openDialog)?_c('div',{staticClass:"tooltiptext pr-2 pl-2 cursor-icon",attrs:{"id":"iiotDNAUndefinedErrorMessageDialog"}},[_c('p',{staticClass:"feedback-title-font mb-3 mt-1 ml-n1"},[_vm._v(" "+_vm._s(_vm.$t('nodes.dna.errorFeedbackTitle'))+" ")]),(!!_vm.message)?_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"opacity-5 feedback-text-color"},[_vm._v(" "+_vm._s(_vm.$t('nodes.dna.errorMessage'))+" ")]),_c('span',{staticClass:"feedback-text-color",attrs:{"id":"iiotDNAUndefinedErrorMessage"}},[_vm._v(_vm._s(_vm.message))])]):_vm._e()]):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }