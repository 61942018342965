var render = function render(){var _vm=this,_c=_vm._self._c;return (
    !_vm.isTypeOf(_vm.node, _vm.TREE_NODE_TYPES.NODE) &&
    !_vm.isTypeOf(_vm.node, _vm.TREE_NODE_TYPES.UNASSIGNED) &&
    (_vm.canAccess('UI_NODE_TREE:ADD') || _vm.canAccess('UI_NODE_TREE:EDIT') || _vm.canAccess('UI_NODE_TREE:DELETE'))
  )?_c('v-list-item-action',{staticClass:"tree-node-action-menu--wrapper"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"id":`iiotNodeTreeItem${_vm.node.title}Menu`,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1735034812)},[_c('v-list',[(_vm.canAccess('UI_NODE_TREE:ADD'))?_c('v-list-item',{attrs:{"id":`iiotNodeTreeItemRandom folderMenuItemAdd`},on:{"click":function($event){return _vm.$store.dispatch('node-tree/toggle_add_edit_dialog', {
            isShown: true,
            parentId: _vm.node.data.id,
          })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('globals.add')))])],1):_vm._e(),(_vm.isTypeOf(_vm.node, _vm.TREE_NODE_TYPES.FOLDER) && _vm.canAccess('UI_NODE_TREE:EDIT'))?_c('v-list-item',{attrs:{"id":`iiotNodeTreeItemRandom folderMenuItemEdit`},on:{"click":function($event){return _vm.$store.dispatch('node-tree/toggle_add_edit_dialog', { isShown: true, id: _vm.node.data.id })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('globals.edit')))])],1):_vm._e(),(_vm.isTypeOf(_vm.node, _vm.TREE_NODE_TYPES.FOLDER) && _vm.canAccess('UI_NODE_TREE:DELETE'))?_c('v-list-item',{attrs:{"id":`iiotNodeTreeItemRandom folderMenuItemDelete`},on:{"click":function($event){return _vm.remove(_vm.node)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('globals.remove')))])],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }