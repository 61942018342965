<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-list-item-action
    v-if="
      !isTypeOf(node, TREE_NODE_TYPES.NODE) &&
      !isTypeOf(node, TREE_NODE_TYPES.UNASSIGNED) &&
      (canAccess('UI_NODE_TREE:ADD') || canAccess('UI_NODE_TREE:EDIT') || canAccess('UI_NODE_TREE:DELETE'))
    "
    class="tree-node-action-menu--wrapper"
  >
    <v-menu bottom left>
      <template #activator="{ on, attrs }">
        <v-btn :id="`iiotNodeTreeItem${node.title}Menu`" v-bind="attrs" class="mt-2" icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="canAccess('UI_NODE_TREE:ADD')"
          :id="`iiotNodeTreeItemRandom folderMenuItemAdd`"
          @click="
            $store.dispatch('node-tree/toggle_add_edit_dialog', {
              isShown: true,
              parentId: node.data.id,
            })
          "
        >
          <v-list-item-title>{{ $t('globals.add') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isTypeOf(node, TREE_NODE_TYPES.FOLDER) && canAccess('UI_NODE_TREE:EDIT')"
          :id="`iiotNodeTreeItemRandom folderMenuItemEdit`"
          @click="$store.dispatch('node-tree/toggle_add_edit_dialog', { isShown: true, id: node.data.id })"
        >
          <v-list-item-title>{{ $t('globals.edit') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isTypeOf(node, TREE_NODE_TYPES.FOLDER) && canAccess('UI_NODE_TREE:DELETE')"
          :id="`iiotNodeTreeItemRandom folderMenuItemDelete`"
          @click="remove(node)"
        >
          <v-list-item-title>{{ $t('globals.remove') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item-action>
</template>

<script>
import { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';

export default {
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      TREE_NODE_TYPES,
    };
  },
  methods: {
    remove(node) {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'nodes.tree.deleteConfirm',
        subTitle: 'nodes.tree.removeSubTitle',
        callback: async () => {
          try {
            await this.$store.dispatch('node-tree/remove', node);
          } catch (e) {
            this.$log.debug(e);
          }
        },
      });
    },
    isTypeOf(node, type) {
      return node.data.type === type;
    },
  },
};
</script>
<style lang="scss">
.tree-node-action-menu--wrapper {
  background-color: white;
  height: 100%;
}
</style>
