var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"nodeInformationLogLevel",attrs:{"eager":""}},[(_vm.monitoringAndLoggingStatus.logLevels)?_c('v-col',{staticClass:"pl-1 mt-4",attrs:{"cols":"12","md":"12","s":"6","xs":"6"}},[_c('v-row',[_c('span',{staticClass:"node-information-text ml-3 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.logLevels'))+" ")])]),(
        _vm.monitoringAndLoggingStatus &&
        _vm.monitoringAndLoggingStatus.logLevels &&
        _vm.monitoringAndLoggingStatus.logLevels.values &&
        _vm.monitoringAndLoggingStatus.logLevels.values.ovdm &&
        _vm.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm &&
        _vm.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"selector",attrs:{"id":"iiotNodeInformationLogLevelOvdm","label":_vm.$t('nodes.information.nodeManagement'),"items":_vm.logLevelList,"autocomplete":"autocomplete","attach":""},on:{"change":function($event){return _vm.changeMonitoringAndLoggingSetting()}},model:{value:(_vm.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm, "level", $$v)},expression:"monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level"}})],1)],1):_vm._e(),(
        _vm.logLevelListLib.length !== 0 &&
        _vm.monitoringAndLoggingStatus &&
        _vm.monitoringAndLoggingStatus.logLevels &&
        _vm.monitoringAndLoggingStatus.logLevels.values &&
        _vm.monitoringAndLoggingStatus.logLevels.values.libvirt &&
        _vm.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl &&
        _vm.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"selector",attrs:{"id":"iiotNodeInformationLogLevelLibvirt","items":_vm.logLevelListLib,"label":_vm.$t('nodes.information.virtualization'),"autocomplete":"autocomplete","attach":""},on:{"change":function($event){return _vm.changeMonitoringAndLoggingSetting()}},model:{value:(_vm.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl, "level", $$v)},expression:"monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level"}})],1)],1):_vm._e(),(
        _vm.logLevelListCodesys.length !== 0 &&
        _vm.monitoringAndLoggingStatus &&
        _vm.monitoringAndLoggingStatus.logLevels &&
        _vm.monitoringAndLoggingStatus.logLevels.values &&
        _vm.monitoringAndLoggingStatus.logLevels.values.codesys &&
        _vm.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl &&
        _vm.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"selector",attrs:{"id":"iiotNodeInformationLogLevelCodesys","items":_vm.logLevelListCodesys,"label":_vm.$t('nodes.information.codesys'),"autocomplete":"autocomplete","attach":""},on:{"change":function($event){return _vm.changeMonitoringAndLoggingSetting()}},model:{value:(_vm.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl, "level", $$v)},expression:"monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level"}})],1)],1):_vm._e(),_c('v-row',[_c('span',{staticClass:"node-information-text ml-3"},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.setTime'))+" ")])]),_c('v-row',{staticClass:"ml-1 mt-6"},[_c('v-text-field',{staticClass:"default-input-appearance timeInput",attrs:{"id":"iiotNodeInformationLogLevelTimeHours","label":"hours","rules":[_vm.rules.min_hours, _vm.rules.max_hours],"min":_vm.minHours,"max":_vm.maxHours,"type":"number"},model:{value:(_vm.time.hours),callback:function ($$v) {_vm.$set(_vm.time, "hours", $$v)},expression:"time.hours"}}),_c('span',{staticClass:"mt-7 ml-2 mr-2"},[_vm._v(" : ")]),_c('v-text-field',{staticClass:"default-input-appearance timeInput",attrs:{"id":"iiotNodeInformationLogLevelTimeMinutes","label":"minutes","rules":[_vm.rules.min_minutes, _vm.rules.max_minutes],"min":_vm.minMinutes,"max":_vm.maxMinutes,"type":"number"},model:{value:(_vm.time.minutes),callback:function ($$v) {_vm.$set(_vm.time, "minutes", $$v)},expression:"time.minutes"}})],1),_c('span',{staticClass:"text--secondary node-information-text-light"},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.timeDescription'))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }