<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container fluid class="pl-6 pr-0">
    <v-tabs v-model="tab" class="pb-6">
      <v-tab id="iiotNodeTreeTab" :key="tree" autofocus @click="tabChanged(0)">
        <h4>
          <v-icon id="iiotNodesTabIconTree">
            {{ tab === tree ? '$vuetify.icons.nodeTreeBlue' : '$vuetify.icons.nodeTree' }}
          </v-icon>
        </h4>
      </v-tab>
      <v-tab id="iiotNodeListTab" :key="list" @click="tabChanged(1)">
        <h4>
          <v-icon id="iiotNodesTabIconNodeList">
            {{ tab === list ? '$vuetify.icons.treeFolderCollapsed' : '$vuetify.icons.treeFolder' }}
          </v-icon>
        </h4>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="tree">
        <v-container fluid class="pa-0">
          <v-row class="mr-5">
            <v-col cols="3">
              <node-tree />
            </v-col>
            <v-col cols="9">
              <node-information
                :new-tab="newTab"
                @deployed-workload-clicked="(payload) => $emit('deployed-workload-clicked', payload)"
                @connections-btn-clicked="(node) => $emit('connections-btn-clicked', node)"
                @log-level-btn-clicked="(node) => $emit('log-level-btn-clicked', node)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="list">
        <node-list
          :page="page"
          :items-per-page="itemsPerPage"
          :search="search"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="pr-6"
        />
      </v-tab-item>
    </v-tabs-items>

    <add-edit-tree-node-dialog />
  </v-container>
</template>

<script>
import NodeTree from '@/components/nodes/node-tree/NodeTree.vue';
import AddEditTreeNodeDialog from '@/components/nodes/node-tree/helpers/AddEditTreeNodeDialog.vue';
import NodeInformation from '@/components/nodes/node-information/NodeInformation.vue';
import NodeList from '@/components/nodes/node-list/NodeList.vue';
import NodeTreeHelper from '@/store/helpers/node-tree.helper';

export default {
  components: {
    NodeTree,
    AddEditTreeNodeDialog,
    NodeInformation,
    NodeList,
  },
  // eslint-disable-next-line consistent-return
  beforeRouteLeave(to, from, next) {
    if (this.isViewSystemSaveEnabled || this.isDockerSystemSaveEnabled || this.isLogLevelSaveEnabled) {
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'nodes.information.warningDialogTitle',
        subTitle: 'nodes.information.warningMsg',
        // eslint-disable-next-line consistent-return
        callback: () => {
          this.$store.dispatch('nodes/disable_view_system_button', {
            isChangedLogLevel: false,
            isViewChanged: false,
            isChangedDockerSystem: false,
          });
          this.$store.dispatch('nodes/disable_docker_system_button', {
            isChangedLogLevel: false,
            isViewChanged: false,
            isChangedDockerSystem: false,
          });
          this.$store.dispatch('nodes/disable_log_system_button', {
            isChangedLogLevel: false,
            isViewChanged: false,
            isChangedDockerSystem: false,
          });
          return next();
        },
      });
    } else {
      return next();
    }
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: String,
      default: '',
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tab: null,
    tree: 0,
    list: 1,
    newTab: 0,
  }),
  computed: {
    isViewSystemSaveEnabled() {
      return this.$store.getters['nodes/isViewSystemSaveEnabled'];
    },
    isDockerSystemSaveEnabled() {
      return this.$store.getters['nodes/isDockerSystemSaveEnabled'];
    },
    isLogLevelSaveEnabled() {
      return this.$store.getters['nodes/isLogLevelSaveEnabled'];
    },
  },
  beforeMount() {
    // eslint-disable-next-line no-restricted-globals
    this.tab = Number(new URLSearchParams(location.search).get('tab'));
  },
  async mounted() {
    this.tabChanged(this.tab);
    if (this.canAccess('UI_NODE_TREE:NODE_DETAILS')) {
      await NodeTreeHelper.invokeMqttEvents();
    }
  },
  beforeDestroy() {
    NodeTreeHelper.clearExchangeInterval();
  },
  methods: {
    async tabChanged(tab) {
      if (tab === 0) {
        await this.$router
          .push({
            name: 'Nodes',
            query: {
              tab,
            },
          })
          .catch(() => {});
      } else {
        await this.$router
          .push({
            name: 'Nodes',
            query: {
              tab,
              page: 1,
              itemsPerPage: 10,
              sortBy: 'created',
              sortDesc: 'true',
            },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss">
#iiotNodesTabIconTree > svg {
  height: 30px;
  width: 30px;
  path {
    stroke-width: 1px;
  }
}

#iiotNodesTabIconNodeList > svg {
  height: 30px;
  width: 30px;
  path {
    stroke-width: 1px;
  }
}
</style>
