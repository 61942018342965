<!--
 * TTTech nerve-management-system
 * Copyright(c) 2021 TTTech Industrial Automation AG.
 *
 * ALL RIGHTS RESERVED.
 * Usage of this software, including source code, netlists, documentation,
 * is subject to restrictions and conditions of the applicable license
 * agreement with TTTech Industrial Automation AG or its affiliates.
 *
 * All trademarks used are the property of their respective owners.
 * TTTech Industrial Automation AG and its affiliates do not assume any liability
 * arising out of the application or use of any product described or shown
 * herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 * make changes, at any time, in order to improve reliability, function or
 * design.
 *
 * Contact Information:
 * support@tttech-industrial.com
 * TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 * -->

<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn
          v-if="canAccess('UI_NODE:REBOOT')"
          id="iiotNodeRebootBtn"
          :disabled="!selectedNode.isOnline()"
          fab
          height="37"
          width="37"
          elevation="0"
          @click="reboot"
        >
          <img v-if="isRebootDisabled" :src="`/img/restart_icon_disabled.svg`" />
          <img v-else :src="`/img/restart_icon.svg`" />
        </v-btn>
      </div>
    </template>
    <span>{{ $t('nodes.information.rebootNode') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'NodeReboot',
  props: {
    selectedNode: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isRebootDisabled() {
      return !this.selectedNode.isOnline();
    },
  },
  methods: {
    async reboot() {
      const { name, serialNumber } = this.selectedNode;

      const title = this.$t('nodes.reboot.confirmMessage', { name, serialNumber });
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'nodes.information.rebootNode',
        subTitle: title,
        callback: async () => {
          this.$store.dispatch('utils/_api_request_handler/close_confirm');
          await this.$store.dispatch('nodes/reboot', { serialNumber, name });
        },
      });
    },
  },
};
</script>
