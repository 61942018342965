<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <node-tree-header />
    <v-row>
      <v-col>
        <sl-vue-tree
          ref="tree"
          v-model="nodes"
          class="pr-4 node-tree-border"
          @input="isDraggable"
          @drop="handleDroppedNode"
        >
          <template #title="{ node }">
            <v-list-item
              :id="`iiotNodeTreeItem${node.title}`"
              :ripple="false"
              :disabled="isTypeNodeDisabled(node)"
              :class="[
                'pa-0 tree-node ma-2 mr-0 ml-0',
                {
                  'tree-node-expanded': node.isExpanded,
                  'tree-node-selected': node.isSelected && node.data.canBeSelected && !isTypeNodeDisabled(node),
                },
              ]"
              @click="expand(node)"
            >
              <v-list-item-icon class="ml-4">
                <v-icon v-if="!node.isLeaf">
                  {{ node.isExpanded ? 'expand_more' : 'chevron_right' }}
                </v-icon>
                <v-icon v-else>
                  {{ `$vuetify.icons.${node.data.device.connectionStatus}` }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-icon v-if="!node.isLeaf" class="mr-4">
                <v-icon>
                  {{ node.isExpanded ? '$vuetify.icons.treeFolderCollapsed' : '$vuetify.icons.treeFolder' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :title="node.title">
                  {{ node.title }}
                </v-list-item-title>
              </v-list-item-content>
              <root-node-expand-collapse-icon :node="node" />
              <node-tree-action-menu :node="node" />
            </v-list-item>
          </template>
        </sl-vue-tree>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SlVueTree from 'sl-vue-tree';
import 'sl-vue-tree/dist/sl-vue-tree-minimal.css';
import { debounce } from 'lodash';
import NodeTreeModel, { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';
import NodeTreeActionMenu from '@/components/nodes/node-tree/helpers/NodeTreeActionMenu.vue';
import RootNodeExpandCollapseIcon from '@/components/nodes/node-tree/helpers/RootNodeExpandCollapseIcon.vue';
import NodeTreeHeader from '@/components/nodes/node-tree/helpers/NodeTreeHeader.vue';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    NodeTreeActionMenu,
    NodeTreeHeader,
    SlVueTree,
    RootNodeExpandCollapseIcon,
  },
  computed: {
    nodes: {
      get() {
        return this.$store.getters['node-tree/getNodes'];
      },
      // eslint-disable-next-line consistent-return
      set() {
        if (this.canAccess('UI_NODE_TREE:MANIPULATE')) {
          return this.$store.dispatch('node-tree/preserve_selected', this.$refs.tree.getSelected());
        }
      },
    },
  },
  async mounted() {
    try {
      mqtt.subscribeTo('nodeOffboarded', {});
      if (this.nodes.length) {
        await this.$store.dispatch('node-tree/select_node', {
          treeNode: this.$refs.tree.getSelected()[0],
          workloadControlPermission: this.canAccess('UI_WORKLOAD_CONTROL:LIST'),
        });
        return;
      }
      await this.$store.dispatch('node-tree/get_root_node');
      const rootNode = this.$store.getters['node-tree/getNodeByType'](TREE_NODE_TYPES.ROOT);
      await this.$store.dispatch('node-tree/get_children_by_parent_id', rootNode);
      await this.$store.dispatch('node-tree/toggle_expand', { node: rootNode });
    } catch (e) {
      this.$log.debug(e);
    }
  },
  beforeDestroy() {
    mqtt.unsubscribeFrom('nodeOffboarded', {});
  },
  methods: {
    isDraggable() {
      if (!this.canAccess('UI_NODE_TREE:MANIPULATE')) {
        this.$refs.tree.traverse((node, nodeModel) => {
          this.$set(nodeModel, 'isDraggable', false);
        });
      }
    },
    // eslint-disable-next-line func-names
    expand: debounce(async function (node, isForced = false) {
      try {
        let loaderTimeout = null;
        switch (node.data.type) {
          case TREE_NODE_TYPES.UNASSIGNED:
            await this.$store.dispatch('node-tree/get_children_by_type', node);
            break;
          case TREE_NODE_TYPES.NODE:
            if (!this.canAccess('UI_NODE_TREE:NODE_DETAILS')) {
              return;
            }
            loaderTimeout = setTimeout(() => {
              this.$store.dispatch('utils/_api_request_handler/show_loader_circular');
            }, 500);
            try {
              await this.$store.dispatch('node-tree/select_node', {
                treeNode: node,
                workloadControlPermission: this.canAccess('UI_WORKLOAD_CONTROL:LIST'),
              });
              clearTimeout(loaderTimeout);
              this.$store.dispatch('utils/_api_request_handler/close_loader_circular');
              return;
            } catch {
              clearTimeout(loaderTimeout);
              this.$store.dispatch('utils/_api_request_handler/close_loader_circular');
              return;
            }
          default:
            await this.$store.dispatch('node-tree/get_children_by_parent_id', node);
        }
        await this.$store.dispatch('node-tree/toggle_expand', { node, isForced });
      } catch (e) {
        this.$log.debug('NodeTree:expand', e.message || e);
      }
    }, 100),
    isParentRootOrUnassigned(parent) {
      return [TREE_NODE_TYPES.UNASSIGNED, TREE_NODE_TYPES.ROOT].includes(parent.data.type);
    },
    isDroppedBeforeOrAfterParent(placement) {
      return ['before', 'after'].includes(placement);
    },
    /**
     * Hack for getting correct parent from
     * the tree library using traverse method,
     * to go trough current state of the tree and
     * get correct state of the tree
     * @param firstDraggedNode
     * @returns {*}
     */
    getCorrectParent(firstDraggedNode) {
      let parentNode;
      // eslint-disable-next-line consistent-return
      this.$refs.tree.traverse((node) => {
        if (node.data.id === firstDraggedNode.data.id) {
          const path = [...node.path];
          path.pop();
          parentNode = this.$refs.tree.getNode(path);
          return false;
        }
      });

      return parentNode;
    },
    isDroppedBeforeAndAfterParentAndUnassigned(parent, placement) {
      return this.isParentRootOrUnassigned(parent) && this.isDroppedBeforeOrAfterParent(placement);
    },
    isFolderDroppedInsideOrAfterUnassigned(draggedNodes, parent, placement) {
      if (parent && parent.title !== 'Root' && parent.data && parent.data.parentId === '') {
        return true;
      }

      const isEveryFolder = draggedNodes.every((n) => n.isTypeOf(TREE_NODE_TYPES.FOLDER));

      return parent.isTypeOf(TREE_NODE_TYPES.UNASSIGNED) && isEveryFolder && placement !== 'before';
    },
    isNodeDroppedInRootOrUnassigned(draggedNodesModel, parent) {
      return draggedNodesModel.some((n) => n.isTypeOf(TREE_NODE_TYPES.NODE)) && this.isParentRootOrUnassigned(parent);
    },
    // eslint-disable-next-line consistent-return
    async handleDroppedNode(draggedNodes, parent) {
      try {
        if (this.canAccess('UI_NODE_TREE:MANIPULATE')) {
          let parentNode = new NodeTreeModel(parent.node); // map to model to have methods available
          const draggedNodesModel = draggedNodes.map((n) => new NodeTreeModel(n));

          // eslint-disable-next-line default-case
          switch (true) {
            case this.isDroppedBeforeAndAfterParentAndUnassigned(parentNode, parent.placement):
            case this.isFolderDroppedInsideOrAfterUnassigned(draggedNodesModel, parentNode, parent.placement):
            case this.isNodeDroppedInRootOrUnassigned(draggedNodesModel, parentNode):
              return this.$store.dispatch('node-tree/reset_state'); // fix to forbid drop
          }

          const firstDraggedNode = new NodeTreeModel(draggedNodes[0]);
          parentNode = this.getCorrectParent(firstDraggedNode);
          if (
            this.isNodeDroppedInRootOrUnassigned(draggedNodesModel, parentNode) &&
            parentNode.data.type !== TREE_NODE_TYPES.FOLDER
          ) {
            return this.$store.dispatch('node-tree/reset_state');
          }

          await this.$store.dispatch('node-tree/handle_dropped_node', { draggedNodes, parentNode });
          await this.expand(parentNode, true);
        }
      } catch (e) {
        this.$log.debug(e);
      }
    },
    isTypeNodeDisabled(node) {
      return !this.canAccess('UI_NODE_TREE:NODE_DETAILS') && node.data.type === TREE_NODE_TYPES.NODE;
    },
  },
};
</script>
<style lang="scss">
.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: transparent;
}

.sl-vue-tree-cursor-inside {
  border-top: 0.5px solid var(--v-primary-base);
  border-bottom: 0.5px solid var(--v-primary-base);
  height: 64px;
}

.sl-vue-tree-toggle,
.sl-vue-tree-drag-info {
  display: none;
}
.sl-vue-tree.sl-vue-tree-root {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: 68vh;
  scroll-margin: 10px;
}
.sl-vue-tree.sl-vue-tree-root::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: var(--v-secondary-base);
}

.sl-vue-tree-title {
  width: 100%;
}
.sl-vue-tree-cursor {
  border: 1px solid var(--v-primary-base);
  width: calc(100% - var(--depth) * 18px);
  margin-left: calc(var(--depth) * 18px);
}

.tree-node {
  background-color: var(--v-treeNode-base);
  height: 48px;

  path {
    stroke-width: 2px;
  }

  .v-list-item__subtitle,
  .v-list-item__title {
    width: 50px; // hack for making item content indented properly
  }

  &-expanded {
    background-color: var(--v-treeNodeExpanded-base);
  }

  &-selected {
    background-color: var(--v-success-lighten5);
  }

  &:focus {
    -webkit-tap-highlight-color: transparent;
    background-color: var(--v-treeNode-base);
  }
}

.node-tree-border {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
