var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"nodeInformationViewSystem"},[_c('v-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"12","s":"6","xs":"6"}},[_c('v-row',[_c('span',{staticClass:"node-information-title",attrs:{"id":"iiotNodeInformationViewSystemTitle"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.system'))+" ")])]),_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"rowHeight mt-5"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[(_vm.canAccess('UI_NODE:SHOW_LOGS'))?_c('v-btn',{staticClass:"pa-0",attrs:{"id":"iiotNodeActionsAndInfoShowLogs","href":_vm.elkUrl,"text":"","target":"_blank","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.internalLogs'))+" ")]):_vm._e(),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3",attrs:{"id":"iiotNodeInformationViewSystemLogsDescription"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.systemLogsDescription'))+" ")])])],1)],1),(_vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS'))?_c('v-row',{staticClass:"rowHeight",class:{ 'mt-15': _vm.isMarginVisible }},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('div',[_c('v-btn',{staticClass:"pa-0",attrs:{"id":"iiotNodeInformationViewSystemMetrics","text":"","color":"primary","href":_vm.systemUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.systemMetrics'))+" ")]),_c('v-row',[_c('span',{staticClass:"text--secondary node-information-text-light mt-3 ml-3",attrs:{"id":"iiotNodeInformationViewSystemMetricsDescription"}},[_vm._v(" "+_vm._s(_vm.$t('nodes.information.systemMetricsDescription'))+" ")])])],1)]),(
          _vm.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && _vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
        )?_c('v-col',{attrs:{"cols":"1"}},[(
            _vm.selectedNode.isOnline() &&
            _vm.monitoringAndLoggingStatus &&
            _vm.monitoringAndLoggingStatus.loggingMonitoring &&
            !_vm.filebeatError
          )?_c('v-checkbox',{staticClass:"pr-5",attrs:{"id":"iiotNodeInformationViewSystemMetricsCheckbox","data-cy":"iiotNodeInformationViewSystemMetricsCheckbox"},on:{"change":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.loggingMonitoring.systemMonitoring),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus.loggingMonitoring, "systemMonitoring", $$v)},expression:"monitoringAndLoggingStatus.loggingMonitoring.systemMonitoring"}}):_vm._e()],1):_vm._e(),(
          !_vm.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && _vm.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
        )?_c('v-col',{attrs:{"cols":"1"}},[(_vm.selectedNode.isOnline() && _vm.monitoringAndLoggingStatus && !_vm.filebeatError)?_c('v-checkbox',{staticClass:"pr-5",attrs:{"id":"iiotNodeInformationViewSystemMetricsCheckbox","data-cy":"iiotNodeInformationViewSystemMetricsCheckbox"},on:{"change":_vm.changed},model:{value:(_vm.monitoringAndLoggingStatus.systemMonitoring),callback:function ($$v) {_vm.$set(_vm.monitoringAndLoggingStatus, "systemMonitoring", $$v)},expression:"monitoringAndLoggingStatus.systemMonitoring"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }