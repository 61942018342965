<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-row align="center" class="status-bar ml-0 mt-0">
    <v-col cols="3" class="text-right">
      <p class="cpu-ram-size mb-0">{{ statusName }}</p>
    </v-col>
    <v-col cols="9" class="progress-column">
      <div v-if="aboveProgress" class="above-progress text--secondary">
        {{ aboveProgress }}
      </div>
      <v-progress-linear
        :value="progressPercent"
        :color="progressPercent < 70 ? 'green-progress-bar' : 'red-progress-bar'"
        class="text--secondary progress-bar"
        background-color="#efefef"
        height="15"
      >
        <template #default="{ value }">
          <span class="cpu-ram-size"> {{ Number.isInteger(value) ? value : value.toFixed(1) }}% </span>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    progressPercent: {
      type: Number,
      default: 0,
    },
    redLimit: {
      default: 80,
      type: Number,
    },
    statusName: {
      type: String,
      default: null,
    },
    aboveProgress: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.status-bar {
  min-height: 50px !important;

  .progress-column {
    position: relative;
    .above-progress {
      position: absolute;
      top: -5px !important;
      font-size: 10px;
    }

    .progress-bar {
      border-left: 2px solid gray;
    }
  }
  .green-progress-bar {
    height: 100%;
    background-image: linear-gradient(to left, #c9ec3b, #f0f3f5);
  }

  .red-progress-bar {
    height: 100%;
    background-image: linear-gradient(to left, #ff4f45, #f0f3f5);
  }
  .cpu-ram-size {
    font-size: 12px;
    line-height: 15px !important;
  }
}
</style>
