<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-row align="end" justify="start">
    <v-col v-for="device in devices" :key="device.id" class="card-max-width">
      <v-card
        v-if="device.type"
        :id="`iiotNodeDevicesWorkload${device.name}`"
        :data-cy="`iiotNodeDevicesWorkload${device.name}`"
        :class="[device.type, 'text-center']"
        :disabled="device.isDisabled()"
        height="110"
        max-width="150"
        min-width="130"
        @click="canAccess('UI_WORKLOAD_CONTROL:VIEW') ? $emit('deployed-workload-clicked', device) : ''"
      >
        <v-row align="center" justify="center" no-gutters class="white--text pa-0 pt-1">
          <v-col cols="12">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" class="mx-2 text-truncate" v-on="on">
                  {{ device.name }}
                </div>
              </template>
              <span>{{ device.name }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-icon v-if="device.type === 'docker-compose'" size="60">
              {{ '$vuetify.icons.compose' }}
            </v-icon>
            <v-icon v-else size="60">
              {{ `$vuetify.icons.${device.type}` }}
            </v-icon>
          </v-col>
          <v-col cols="12">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" class="text-truncate mx-1" v-on="on">
                  <span>{{ $t('nodes.information.devices.statusText') }}: </span>
                  <span class="text-uppercase font-weight-bold">
                    {{ $t(`nodes.information.devices.status.${device.currentStatus}`) }}</span
                  >
                </div>
              </template>
              <span>{{ $t('nodes.information.devices.statusText') }}: </span>
              <span class="text-uppercase font-weight-bold">
                {{ $t(`nodes.information.devices.status.${device.currentStatus}`) }}</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.docker {
  background-image: linear-gradient(to bottom, #ff9a00, #ff6100);
}

.vm {
  background-image: linear-gradient(to top, #3810f2, #6f42c1);
}

.codesys {
  background-image: linear-gradient(to top, #dc3545, #e83e8c);
}
.docker-compose {
  background-image: linear-gradient(to top, #ffac10, #e9d400);
}
.card-max-width {
  max-width: 150px;
}
</style>
