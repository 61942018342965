var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('node-tree-header'),_c('v-row',[_c('v-col',[_c('sl-vue-tree',{ref:"tree",staticClass:"pr-4 node-tree-border",on:{"input":_vm.isDraggable,"drop":_vm.handleDroppedNode},scopedSlots:_vm._u([{key:"title",fn:function({ node }){return [_c('v-list-item',{class:[
              'pa-0 tree-node ma-2 mr-0 ml-0',
              {
                'tree-node-expanded': node.isExpanded,
                'tree-node-selected': node.isSelected && node.data.canBeSelected && !_vm.isTypeNodeDisabled(node),
              },
            ],attrs:{"id":`iiotNodeTreeItem${node.title}`,"ripple":false,"disabled":_vm.isTypeNodeDisabled(node)},on:{"click":function($event){return _vm.expand(node)}}},[_c('v-list-item-icon',{staticClass:"ml-4"},[(!node.isLeaf)?_c('v-icon',[_vm._v(" "+_vm._s(node.isExpanded ? 'expand_more' : 'chevron_right')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(`$vuetify.icons.${node.data.device.connectionStatus}`)+" ")])],1),(!node.isLeaf)?_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v(" "+_vm._s(node.isExpanded ? '$vuetify.icons.treeFolderCollapsed' : '$vuetify.icons.treeFolder')+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":node.title}},[_vm._v(" "+_vm._s(node.title)+" ")])],1),_c('root-node-expand-collapse-icon',{attrs:{"node":node}}),_c('node-tree-action-menu',{attrs:{"node":node}})],1)]}}]),model:{value:(_vm.nodes),callback:function ($$v) {_vm.nodes=$$v},expression:"nodes"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }